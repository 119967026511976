
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import EventSuperAdminTemplate from '@/components/Seller/EventSuperAdminTemplate.vue'
import SkeletonTemplate from '@/components/Seller/SkeletonTemplate.vue'
import EventTemplate from '@/components/Seller/EventTemplate.vue'

import {
  getUpcomingEventsBySeller,
  getPastEventsBySeller,
  getInactiveEventsBySeller,
  getPetitions,
  getAllBusiness,
} from '@/services/seller.service'
import { Event } from '@/interfaces/product.interface'
import { ErrorMessages } from '@/helpers/constants'
import { Action } from 'vuex-class'
import { getMyData } from '@/services/user.service'

@Component({
  components: {
    SellerHeader,
    EventSuperAdminTemplate,
    EventTemplate,
    SkeletonTemplate,
  },
})
export default class Events extends Vue {
  public upcomingEvents: Event[] = []
  public pastEvents: Event[] = []
  public inactiveEvents: Event[] = []

  @Action setEventSelected!: Function
  public filterUpcoming = ''
  public filterPast = ''
  public filterByID = ''
  public filterByBusiness: any = ''

  public upcomingEventsFiltered: Event[] = []
  public upcomingEventPage = 1
  public upcomingEventTotalPages = 1

  public pastEventsFiltered: Event[] = []
  public pastEventPage = 1
  public pastEventTotalPages = 1

  public inactiveEventsFiltered: Event[] = []
  public inactiveEventPage = 1
  public inactiveEventTotalPages = 1

  public businessAdmin = false
  public skeletonCount = [0, 1, 2]
  public loadingUpcoming = false
  public loadingPast = false
  public loadingInactive = false

  public isSuperAdmin = false;
  public eventsPerPage = 20;

  public title = 'Mis eventos'

  public business = null
  public sellerCode = null
  public timeout = null;

  async getUpcomingEvents() {
    try {
      this.loadingUpcoming = true
      const { meta, data } = await getUpcomingEventsBySeller(
        this.upcomingEventPage,
        this.eventsPerPage,
        this.filterUpcoming,
        this.filterByBusiness
      )
      this.upcomingEventTotalPages = meta.last_page
      this.upcomingEvents = data
      this.upcomingEventsFiltered = this.upcomingEvents;
      this.loadingUpcoming = false
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  searchUpcomingEvents() {
    clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.filterByBusiness = null
        this.getUpcomingEvents();
      }, 800);
  }

  async getPastEvents() {
    try {
      this.loadingPast = true
      const { meta, data } = await getPastEventsBySeller(
        this.pastEventPage,
        this.eventsPerPage,
        this.filterPast,
        this.filterByBusiness
      )
      this.pastEventTotalPages = meta.last_page
      this.pastEvents = data
      this.pastEventsFiltered = this.pastEvents
      this.loadingPast = false
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  searchPastEvents() {
    clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.filterByBusiness = null
        this.getPastEvents();
      }, 800);
  }

  async getInactiveEvents(page?: number, query?: string, business_id?: number) {
    try {
      this.loadingInactive = true
      const { meta, data } = await getInactiveEventsBySeller(
        page,
        this.eventsPerPage,
        query,
        business_id
      );
      this.inactiveEventTotalPages = 0;
      this.inactiveEvents = data;
      this.inactiveEventsFiltered = this.inactiveEvents;

      this.loadingInactive = false
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  mounted() {
    this.updatePetitions()
    this.getMyData()
  }

  async getMyData() {
    try {
      const { data } = await getMyData()

      // Este permiso es el flag del seller, está checkeado a la fecha.
      this.businessAdmin = data.business_admin == 1 ? true : false
      this.isSuperAdmin = data.role_id == 7 ? true : false
      this.sellerCode = data.code

      if (this.isSuperAdmin) {
        this.title = 'Todos los eventos'
        // Traer los business para el filtro (select)
        await this.getAllBusiness()
      }
    } catch (e) {
      Vue.$toast.error(ErrorMessages.SESION_EXPIRED)
      this.$router.push('/login')
      console.error(e)
    }
  }

  async getAllBusiness() {
    const { data } = await getAllBusiness()
    this.business = data.data
  }

  goToAdmin() {
    this.$router.push('/seller/administration')
  }

  async updatePetitions() {
    // Contar peticiones desde acá para que cada vez que entre a la App, se actualice el contador de peticiones
    const petitions = await getPetitions(true)
    localStorage.setItem('petitions', petitions.data.petitions)
    localStorage.setItem('pending_petitions', petitions.data.pending_petitions)
  }

  restartFilterQuery() {
    this.filterUpcoming = null
    this.filterPast = null
  }

  restartFilterBusiness() {
    this.filterByBusiness = null
  }

  @Watch('upcomingEventPage', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUpcomingPageChange(nextPage: number, _currentPage: unknown) {
    this.upcomingEventPage = nextPage;
    this.getUpcomingEvents()
  }

  @Watch('pastEventPage', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPastPageChange(nextPage: number, _currentPage: unknown) {
    this.pastEventPage = nextPage;
    this.getPastEvents()
  }

  @Watch('inactiveEventPage', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onInactivePageChange(nextPage: number, _currentPage: unknown) {
    this.getInactiveEvents(nextPage)
  }
}
