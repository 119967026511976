
import { Component, Prop, Vue } from 'vue-property-decorator'
import { eventDate, isoDateToTextDate } from '@/helpers/date'
import router from '@/router'

@Component({
  components: {},
})
export default class EventSuperAdminTemplate extends Vue {
  @Prop() readonly event: any

  formatPrice(value) {
    const val = (value / 1).toFixed().replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  dateFormat(isoDate) {
    return isoDateToTextDate(isoDate)
  }

  goToPricing(id) {
    router.push('/seller/pricing/' + id)
  }

  formatTypePricing(type) {
    if (type == 'fixed') {
      return 'Fijo'
    } else if (type == 'variable') {
      return 'Variable'
    } else if (type == 'mixed') {
      return 'Mixto'
    }
  }

  goToGlobalReport(eventId: number) {
    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN + '/reports/global/' + eventId
  }
}
